body {
  color: $black;
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  font-weight: 400;
}

.container {
  // extra large
  @include respons-xl {
    width: 1600px;
  }
}

h1 {
  color: $black;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 24px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 24px;

  @include respons-sm {
    font-size: 36px;
    margin-top: 36px;
    margin-bottom: 36px;
  }

  @include respons-lg {
    font-size: 48px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

h2 {
  text-transform: uppercase;
}

p {
  line-height: 1.714285714;
}

.yellow {
  color: $color2;
}

.green {
  color: $color1;
}

.green-light {
  color: $color1-light;
}

hr {
  display: block;
  width: 136px;
  height: 5px;
  margin: 0 auto 0 0;
  border: none;
  background-color: $white;

  @include respons-lg {
    width: 144px;
    height: 7px;
  }

  &.hr-inline {
    display: inline-block;
  }

  &.hr-center {
    margin: 0 auto;
  }

  &.hr-right {
    float: right;
  }

  &.hr-default {
    background-color: $color2;
  }

  &.hr-green {
    background-color: $color1;
  }

  &.hr-green-light {
    background-color: $color1-light;
  }

  &.hr-block {
    display: block;
  }
}

.yellow-container {
  background-color: $color2;
}

.grey-container {
  background-color: $color5;
}

.green-container {
  background-color: $color1;

  h2, h3, h4, h5, h6 {
    color: $white !important;
  }
}

.green-light-container {
  background-color: $color1-light;

  h2, h3, h4, h5, h6 {
    color: $white !important;
  }
}

.margin-bottom {
  margin-bottom: 70px;
}

.no-image {
  background-image: url('../img/noimage.jpg');
}
