.contacts {
  padding-top: 30px;
  padding-bottom: 30px;

  @include respons-sm {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.contact {
  margin-top: 30px;
  margin-bottom: 30px;

  @include respons-sm {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  h3 {
    @extend .green;
    font-size: 20px;
    margin-top: 0;

    @include respons-sm {
      font-size: 15px;
    }

    @include respons-md {
      font-size: 15px;
    }

    @include respons-lg {
      font-size: 18px;
      margin-bottom: 25px;
    }


    > small {
      @extend .green;
      display: block;
      margin-top: 13px;
      font-size: 15px;
      text-transform: none;

      @include respons-sm {
        font-size: 13px;
      }

      @include respons-md {
        font-size: 14px;
      }

      @include respons-lg {
        font-size: 16px;
      }
    }
  }

  hr {
    display: block;
    margin-bottom: 10px;

    @include respons-sm {
      margin-bottom: 10px;
    }

    @include respons-md {
      margin-bottom: 10px;
    }

    @include respons-lg {
      margin-bottom: 25px;
    }
  }

  p {
    font-size: 15px;
    line-height: 1.4;

    @include respons-sm {
      font-size: 13px;
    }

    @include respons-md {
      font-size: 14px;
    }

    @include respons-lg {
      font-size: 16px;
    }

    a {
      color: $black;
    }
  }
}