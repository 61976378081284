.header-image {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
    width: 100%;
    padding-bottom: 100%;
    margin-top: 50px;
    background-color: #d0d0d0;

    @include respons-sm {
        padding-bottom: 35%;
    }

    @include respons-md {
        margin-top: 68px;
    }

    @include respons-lg {
        margin-top: 76px;
    }
}

.header-image {
    img {
        display: none;
    }

    > .container {
        position: relative;
        height: 100%;
    }
}
