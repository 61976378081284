.interested {
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;

  h3 {
    margin-bottom: 40px;
  }

  .glyphicon {
    color: $white;
    font-size: 56px;
    position: absolute;
    top: 50%;
    margin-top: -28px;

    &.glyphicon-menu-left {
      left: -1000px;

      @include respons-sm {
        left: -32px;
      }
    }

    &.glyphicon-menu-right {
      right: 1000px;

      @include respons-sm {
        right: -32px;
      }
    }
  }
}