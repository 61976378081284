@mixin respons-sm {
  @media (min-width: $screen-sm) {
    @content;
  }
}

@mixin respons-md {
  @media (min-width: $screen-md) {
    @content;
  }
}

@mixin respons-lg {
  @media (min-width: $screen-lg) {
    @content;
  }
}

@mixin respons-xl {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin rotate($rotation) {
  -webkit-transform: rotate($rotation);
  -moz-transform: rotate($rotation);
  -ms-transform: rotate($rotation);
  -o-transform: rotate($rotation);
  transform: rotate($rotation);
}

@mixin box-sizing($box-sizing) {
  -webkit-box-sizing: $box-sizing;
  -moz-box-sizing: $box-sizing;
  box-sizing: $box-sizing;
}
@mixin transition-property($transition) {
  -webkit-transition-property: $transition;
  -moz-transition-property: $transition;
  -o-transition-property: $transition;
  transition-property: $transition;
}

@mixin transition-duration($duration) {
  -webkit-transition-duration: $duration;
  -moz-transition-duration: $duration;
  -o-transition-duration: $duration;
  transition-duration: $duration;
}

@mixin transition-timing-function($timing){
  -webkit-transition-timing-function: $timing;
  -moz-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  transition-timing-function: $timing;
}