.navbar-default {
  border: none;
  background-color: rgba(255, 255, 255, 1);
}

.navbar-default .navbar-header {
  height: 49px;

  @include respons-sm {
    height: auto;
  }
}

/**
 * HAMBURGER
 */
.navbar-default .navbar-header .menu-toggle {
  font-size: 19px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 49px;
  margin-right: 24px;

  @include respons-sm {
    display: none;
  }

  i {
    font-size: 32px;
    position: relative;
    top: 5px;
    margin-left: 6px;
  }
}

#navbar {
  float: none;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 1);
  z-index: 10;
  padding: 0;
  margin: 0;
  max-height: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  -moz-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;

  @include respons-sm {
    float: right;
    top: 0;
    position: relative;
    max-height: none;
    background-color: transparent;
    overflow-y: auto;
  }

  &.navbar--open {
    max-height: 600px;
  }
}

/**
 * BRAND
 */
.navbar-default .navbar-header {
  position: relative;

  .navbar-brand {
    z-index: 99;
    position: absolute;
    left: 15px;
    top: 0;
    display: inline-block;
    height: 40px;
    padding-top: 9px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    @include respons-md {
      height: 150px;
      padding-top: 18px;
    }

    @include respons-lg {
      height: 171px;
      padding-top: 18px;
    }

    > img {
      max-height: 100%;
    }
  }
}

/**
 * ITEMS
 */
#navbar .navbar-nav {
  @include respons-md {
    margin-right: 0;
  }
}

#navbar .navbar-nav > li {
  > a {
    color: $black;
    text-transform: uppercase;
    font-weight: 800;
    text-align: center;

    @include respons-sm {
      font-size: 11px;
      padding: 14px 15px 14px 5px;
    }

    @include respons-md {
      font-size: 15px;
      padding-top: 24px;
      padding-bottom: 24px;
      padding-left: 12px;
      padding-right: 12px;
    }

    @include respons-lg {
      font-size: 19px;
      padding-top: 28px;
      padding-bottom: 28px;
      padding-left: 15px;
      padding-right: 15px;
    }

    &:hover {
      color: $color1;
    }
  }

  &.active > a {
    color: $black;
    background-color: transparent;

    @include respons-sm {
      border-bottom: solid 5px $color1;
      padding-bottom: 11px;
    }

    @include respons-md {
      padding-bottom: 19px;
    }

    @include respons-lg {
      padding-bottom: 23px;
    }

    &:hover {
      color: $color1;
      background-color: transparent;
    }
  }
}

.navbar-default .lang-switch {
  display: none;
}

.navbar-default .nav-langs {
  position: absolute;
  left: 97px;
  top: 4px;
  width: 125px;
  z-index: 999;

  @include respons-sm {
    left: 80px;
    top: 11px;
  }

  @include respons-md {
    top: 18px;
    left: 142px;
  }

  @include respons-lg {
    top: 22px;
    left: 174px;
  }

  @include respons-xl {
    left: 218px;
  }

  > li {
    float: left;

    > a {
      display: inline-block;
      margin: 0 4px;
      padding: 0;
      color: $white;
      font-weight: 700;
      text-align: center;
      background-color: $color4;

      width: 26px;
      height: 26px;
      line-height: 26px;
      border-radius: 13px;
      font-size: 12px;

      @include respons-md {
        width: 32px;
        height: 32px;
        font-size: 15px;
        line-height: 32px;
        border-radius: 16px;
      }

      &:hover {
        color: $black;
        background-color: $color4;
      }
    }

    &.active > a {
      color: $white;
      background-color: $color1;

      &:hover {
        color: $black;
        background-color: $color1;
      }
    }
  }
}