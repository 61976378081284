.container-service {
  padding-top: 30px;
  padding-bottom: 30px;

  @include respons-sm {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.service-row {
  h2 {
    font-size: 18px;
    margin-bottom: 10px;

    @include respons-md {
      font-size: 24px;
      margin-bottom: 20px;
    }

    @include respons-lg {
      font-size: 24px;
      margin-bottom: 30px;
    }
  }

  hr {
    @include respons-sm {
      margin-bottom: 10px;
    }

    @include respons-md {
      margin-bottom: 20px;
    }

    @include respons-lg {
      margin-bottom: 30px;
    }
  }

  p {
    @include respons-md {
      margin-bottom: 20px;
    }

    @include respons-md {
      margin-bottom: 36px;
    }

    @include respons-lg {
      margin-bottom: 50px;
    }
  }

  img.img-responsive {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}