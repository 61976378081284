/**
 * VENDORS
 */
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap';
@import '../../node_modules/jquery-fancybox/source/scss/jquery.fancybox';
@import '../../node_modules/slick-carousel/slick/slick.scss';
@import '../../node_modules/slick-carousel/slick/slick-theme.scss';

/**
 * BASIC
 */
@import 'base/variables';
@import 'base/mixins';
@import 'base/fonts';
@import 'base/base';
@import 'base/buttons';

/**
 * LAYOUT
 */
@import "layout/header";
@import "layout/header-image";
@import "layout/footer";

/**
 * MODULES
 */
@import "module/navigations";
@import "module/products";
@import "module/reference-menu";
@import "module/news-latest";
@import "module/services";
@import "module/content";
@import "module/interested";
@import "module/contacts";
@import "module/progress";
@import "module/a-z";
@import "module/reference-grid";
@import "module/customers";
@import "module/map";
@import "module/news";
@import "module/reference-table";
@import "module/gallery-row";
@import "module/contact";
@import "module/video-wrapper";
