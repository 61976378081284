.a-z {
  color: $white;

  a {
    color: $white;
    text-decoration: underline;
  }

  @include respons-xl {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  > .container > .row {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  h2 {
    margin-top: 0;

    @include respons-xl {
      font-size: 24px;
      margin-bottom: 12px;
    }
  }

  hr {
    @include respons-xl {
      margin-bottom: 12px;
    }
  }
}