.btn {
  border-radius: 0;
  border: none;
  text-transform: uppercase;
}

.btn-default {
  color: white;
  background-color: $color2;
}

.btn-success {
  color: white;
  background-color: $color1;
}

.btn-white {
  color: $color1;
  background-color: $white;
}

.btn-lg {
  padding-left: 36px;
  padding-right: 36px;
}