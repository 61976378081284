.content-container {
    padding-top: 70px;
    padding-bottom: 70px;

    h2 {
        color: $color1;
        font-size: 30px;
        font-weight: 700;
        text-transform: uppercase;
    }

    h2, h3, h4, h5, h6 {
    }

    hr {
        @extend .hr-green;
        @extend .hr-inline;

        margin-top: 30px;
        margin-bottom: 30px;
    }
}

.header-container h2 {
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase;

    @include respons-md {
        font-size: 22px;
        margin-top: 65px;
        margin-bottom: 15px;
    }

    @include respons-lg {
        font-size: 26px;
        margin-top: 74px;
        margin-bottom: 24px;
    }
}

.header-container .btn-order {
    position: absolute;
    top: -65px;
    right: 50%;
    margin-right: -86px;
    font-size: 9px;

    @include respons-sm {
        top: -80px;
        right: 0;
        margin-right: 0;
        font-size: inherit;
    }

    @include respons-md {
        top: -155px;
    }
}

.header-container .btn {
    font-size: 16px;
    text-transform: uppercase;
    padding: 9px 18px;

    @include respons-sm {
        margin-top: 10px;
        font-size: 11px;
        padding: 6px 10px;
    }

    @include respons-md {
        font-size: 16px;
        font-weight: 500;
        padding: 10px 25px;
        margin-top: 45px;
    }

    @include respons-lg {
        font-size: 16px;
        font-weight: 500;
        padding: 12px 25px;
        margin-top: 60px;
    }

    > span.glyphicon {
        padding-left: 25px;

        @include respons-sm {
            padding-left: 7px;
        }

        @include respons-md {
            padding-left: 18px;
        }

        &.only-icon {
            @media (max-width: $screen-xs-max) {
                padding: 0;
            }
        }
    }

    &.btn-reference-list {
        @media (max-width: $screen-xs-max) {
            margin-top: 8px;
        }
    }
}
