.no-header {
  padding-top: 50px;

  @include respons-md {
    padding-top: 80px;
  }

  @include respons-lg {
    padding-top: 68px;
  }

  h1 {
    margin-left: 0;

    @include respons-md {
      text-align: right;
      margin-left: 205px;
    }

    @include respons-lg {
      margin-left: 235px;
    }
  }
}

header {
  padding-top: 95px;
  padding-bottom: 25px;
  background-color: $color5;

  @include respons-md {
    padding-top: 150px;
    padding-bottom: 25px;
  }

  @include respons-lg {
    padding-top: 170px;
    padding-bottom: 50px;
  }

  > .container {
    position: relative;
    height: 100%;
  }

  h1 {
    margin-top: 0;
    margin-bottom: .25em;

    @include respons-sm {
      font-size: 28px;
    }

    @include respons-md {
      font-size: 32px;
    }

    @include respons-lg {
      font-size: 40px;
    }
  }

  h2 {
    font-size: 16px;

    @include respons-sm {
      font-size: 24px;
    }

    @include respons-xl {
      font-size: 30px;
    }
  }
}
