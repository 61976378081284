.products-container {
    margin-top: 30px;
}

.products {
    text-align: center;

    &__item {
        @include respons-sm {
            display: inline-block;
            float: none;
            margin-left: -2px;
            margin-right: -2px;
        }

        > a  {
            display: block;
            position: relative;
            height: 220px;
            text-align: center;
            padding: 50px;
            margin-bottom: 15px;
            vertical-align: middle;
            overflow: hidden;

            -webkit-transition: padding 250ms ease; /* Safari */
            transition: padding 250ms ease;

            @include respons-sm {
                height: 380px;
                margin-bottom: 30px;
            }

            @include respons-md {
                height: 416px;
            }

            .bg-image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                transition: -webkit-transform 1000ms;
                transition: transform 1000ms;
                transition: transform 1000ms, -webkit-transform 1000ms;
                background-position: 50% 50%;
                background-size: cover;
            }

            .bg-overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: radial-gradient(ellipse farthest-corner at center center, rgba(0, 0, 0, 0.2) 20%, rgba(0, 0, 0, 0.55) 100%) repeat scroll 0% 0%;
                transition: 500ms;
            }

            &:hover {
                text-decoration: none;

                .bg-image {
                    -webkit-transform: scale(1.05);
                    transform: scale(1.05);
                }

                .bg-overlay {
                    opacity: 0.5;
                }
            }

            .data-container {
                display: table;
                width: 100%;
                height: 100%;
                position: relative;

                .data {
                    display: table-cell;
                    vertical-align: middle;
                }
            }

            h2 {
                color: $white;
                font-size: 24px;
                font-weight: 800;
                line-height: 1.3;
                text-transform: uppercase;
                text-shadow: rgba(0, 0, 0, .3) 0 0 18px;
                margin: 0;

                @include respons-sm {
                    font-size: 26px;
                }

                @include respons-md {
                    font-size: 36px;
                }

                @include respons-lg {
                    font-size: 26px;
                }

                @include respons-xl {
                    font-size: 36px;
                }
            }

            hr {
                margin-top: 14px;
                margin-bottom: 0;
                box-shadow: rgba(0, 0, 0, .3) 0 0 18px;
            }
        }

    }
}
