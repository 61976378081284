footer {
  color: $white;
  background-color: $black;

  @include respons-sm {
    text-align: left;
  }

  @include respons-md {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @include respons-lg {
    font-size: 16px;
    padding-top: 75px;
    padding-bottom: 66px;
  }

  a {
    color: $white;

    &:hover {
      color: $color1;
      text-decoration: none;
    }
  }

  hr {
    margin-bottom: 25px;

    @include respons-sm {
      display: block;
    }
  }

  h2 {
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 25px;
    margin-bottom: 25px;

    @include respons-sm {
      display: table;
      font-size: 18px;
      width: 100%;
      height: 2em;
      margin-bottom: 14px;
    }

    @include respons-md {
      font-size: 20px;
    }

    @include respons-lg {
      font-size: 24px;
    }

    > a {
      @include respons-sm {
        display: table-cell;
        vertical-align: bottom;
      }

      > br {
        display: none;

        @include respons-sm {
          display: inline;
        }
      }
    }
  }

  ul {
    padding-left: 18px;
  }
}

footer .row {
  .about-us,
  .what-is-it {
    h2,
    h2 a,
    a:hover {
      color: $color1;
    }

    hr {
      background-color: $color1;
    }
  }
}

footer .row > .services {
  > h2,
  > h2 a,
  a:hover {
    color: $color2;
  }

  hr {
    background-color: $color2;
  }
}

footer .row > .footer-contact {
  padding-top: 38px;
  padding-bottom: 50px;

  @include respons-sm {
    padding-top: 0;
    padding-bottom: 0;
  }

  > h2,
  > h2 a {
    color: $white;
  }

  hr {
    background-color: $white;
  }

  address {
    margin-top: 14px;
    margin-bottom: 25px;

    > a {
      color: $color1;
    }
  }

  .socials a {
    color: $white;
    font-size: 25px;
    margin-left: 6px;
    margin-right: 6px;
  }
}