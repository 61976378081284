.contact-left {
    padding-right: 15px;
    text-align: center;

    @include respons-sm {
        padding-right: 30px;
        text-align: right;
    }

    @include respons-md {
    }
}

.contact-right {
    padding-left: 15px;
    text-align: center;

    @include respons-sm {
        padding-left: 30px;
        text-align: left;
    }

    @include respons-md {
    }
}

.contact-left,
.contact-right {
    hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    a {
        color: $black;
    }
}

.contact-left address,
.contact-right address {

    @include respons-sm {
        font-size: 20px;
        margin-top: 10px;
    }

    @include respons-md {
        font-size: 30px;
        margin-top: 10px;
    }
}
