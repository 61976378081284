.customers {
  padding-top: 60px;
  padding-bottom: 60px;

  h3 {
    margin-top: 20px;
    margin-bottom: 14px;

    a {
      color: $white;
    }
  }

  hr {
    margin-bottom: 14px;
  }

  .glyphicon {
    color: $white;
    font-size: 56px;
    position: absolute;
    top: 50%;
    margin-top: -28px;
  }

  .glyphicon.glyphicon-menu-left {
    left: -1000px;
    z-index: 600;

    @include respons-sm {
      left: -45px;
    }

    @media screen and (max-width: 1672px) and (min-width: 1600px) {
      left: 25px;
      color: black;
    }

    @media screen and (max-width: 1240px) and (min-width: $screen-lg-min) {
      left: 25px;
      color: black;
    }

    @media screen and (max-width: 1044px) and (min-width: $screen-md-min) {
      left: 25px;
      color: black;
    }

    @media screen and (max-width: 820px) and (min-width: $screen-sm-min) {
      left: 25px;
      color: black;
    }
  }

  .glyphicon.glyphicon-menu-right {
    right: 1000px;
    z-index: 600;

    @include respons-sm {
      right: -45px;
    }

    @media screen and (max-width: 1672px) and (min-width: 1600px) {
      right: 25px;
      color: black;
    }

    @media screen and (max-width: 1240px) and (min-width: $screen-lg-min) {
      right: 25px;
      color: black;
    }

    @media screen and (max-width: 1044px) and (min-width: $screen-md-min) {
      right: 25px;
      color: black;
    }

    @media screen and (max-width: 820px) and (min-width: $screen-sm-min) {
      right: 25px;
      color: black;
    }
  }
}