.latest-news {

  .news-main,
  .news-secondary {
    padding-top: 30px;
    padding-bottom: 30px;
    color: $white;
    text-align: center;

    @include respons-sm {
      height: 350px;
      text-align: left;
      padding-top: 0;
      padding-bottom: 0;
    }

    @include respons-md {
      height: 600px;
      padding-top: 0;
      padding-bottom: 0;
    }

    @include respons-lg {
      height: 600px;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .news-main {
    overflow: hidden;
    background-color: $color4;

    @include respons-sm {
      background-color: transparent;
    }

    .bg-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: -webkit-transform 1000ms;
      transition: transform 1000ms;
      transition: transform 1000ms, -webkit-transform 1000ms;
      background-position: 50% 50%;
      background-size: cover;
    }

    .bg-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(ellipse farthest-corner at center center, rgba(0,0,0,0.2) 20%, rgba(0,0,0,0.55) 100%) repeat scroll 0% 0%;
      transition: 500ms;
    }


    .news-container {
      position: relative;

      @include respons-sm {
        padding-right: 20px;
      }

      @include respons-md {
        padding-right: 40px;
      }

      @include respons-lg {
        padding-right: 80px;
      }
    }

    @include respons-sm {
      background-image: inherit;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .news-secondary {
    background-color: $color1;

    @include respons-sm {
      padding-left: 30px;
    }
  }

  .latest-news-content {
    &:hover .bg-image {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
    &:hover .bg-overlay {
      opacity: 0.5;
    }

    > .container-fluid {
      display: none;

      @include respons-sm {
        display: block;
      }
    }

    h3 {
      color: $white;
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;

      @include respons-sm {
        margin-top: 65px;
        font-size: 20px;
      }

      @include respons-md {
        font-size: 26px;
        min-height: 74px;
        margin-top: 154px;
        margin-bottom: 0;
      }

      @include respons-lg {
        font-size: 26px;
        min-height: 74px;
        margin-top: 154px;
        margin-bottom: 0;
      }

      @include respons-xl {
        font-size: 34px;
        min-height: 74px;
        margin-top: 154px;
        margin-bottom: 0;
      }

      > a {
        color: $white;
      }
    }

    .datetime {
      color: $white;
      font-size: 11px;

      @include respons-sm {
        margin-bottom: 9px;
      }

      @include respons-md {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 16px;
      }
    }

    hr {
      display: none;

      @include respons-sm {
        display: block;
        margin-bottom: 17px;
      }

      @include respons-md {
        margin-bottom: 35px;
      }
    }

    p {
      display: none;
      line-height: 1.3;
      overflow: hidden;
      text-overflow: ellipsis;

      @include respons-sm {
        display: block;
        height: 163px;
        font-size: 13px;
      }

      @include respons-md {
        font-size: 18px;
        height: 243px;
      }

      @include respons-lg {
        font-size: 18px;
        height: 243px;
      }

      @include respons-xl {
        font-size: 21px;
        height: 243px;
      }
    }
  }

  .container-overlay {
    position: relative;

    > .row {
      @include respons-sm {
        position: absolute;
        top: 0;
        left: 15px;
        right: 15px;
        z-index: 999;
      }
    }
  }

  .btn {
    &.btn-header {
      //margin-right: 80px;
    }

    &.btn-footer {
      margin-top: 30px;
      margin-bottom: 30px;

      span.glyphicon {
        padding-left: 25px;
      }
    }
  }
}