.references-grid .references-grid-item {
  padding-top: 30px;
  padding-bottom: 30px;

  @include respons-sm {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  h2 {
    font-weight: 800;
    font-size: 18px;
    min-height: 2.2em;
    margin: 0 0 12px 0;

    @include respons-md {
      font-size: 16px;
    }

    @include respons-lg {
      font-size: 16px;
      margin-bottom: 12px;
    }

    @include respons-xl {
      font-size: 23px;
    }

    a {
      color: $black;
    }
  }

  .references-grid-item-list {
    margin: 30px 0 0 0;
    height: 116px;
  }

  ul {
    margin: 0;
    padding-left: 24px;

    > li > a {
      color: $black;
      font-size: 14px;

      @include respons-lg {
        font-size: 17px;
      }

      @include respons-xl {
        font-size: 21px;
      }

      &:hover {
        color: $color2;
      }
    }
  }
}