.news-row {
  padding-top: 70px;

  > div {
    padding: 0 15px;
    margin-bottom: 70px;

    @include respons-sm {
      padding: 30px;
    }

    &:hover {
      color: $white !important;
      background-color: $color1;

      h2,
      h2 a {
        color: $white !important;
      }

      hr {
        @extend .hr-default;
      }

      p {
        color: $white !important;
      }

      .btn {
        @extend .btn-white;
      }
    }
  }

  h2 {
    min-height: 2.2em;
  }

  h2,
  h2 a {
    color: $color1;
    font-size: 20px;
    line-height: 1.4;
    margin: 0 0 20px 0;

    @include respons-sm {
      font-size: 16px;
    }

    @include respons-lg {
      font-size: 20px;
    }

    @include respons-xl {
      font-size: 30px;
    }
  }

  hr {
    margin-bottom: 30px;

    @include respons-sm {
      margin-bottom: 60px;
    }
  }

  p {
    margin-top: 20px;
    margin-bottom: 0;

    @include respons-lg {

    }
  }

  p.datetime {
    @extend .green;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .btn {
    margin-top: 30px;
  }
}

header .news-row {
  padding-top: 0;
  padding-bottom: 0;
}
